import * as React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TriangleRight = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        height="30"
        viewBox="0 0 26 30"
        width="26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.14359L25 15L0.999999 28.8564L1 1.14359Z"
          fill="white"
          stroke="black"
        />
      </svg>
    </SvgIcon>
  );
};

export const DownArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        height="800px"
        stroke="currentColor"
        viewBox="0 0 24 24"
        width="800px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          <path
            d="M11.1808 15.8297L6.54199 9.20285C5.89247 8.27496 6.55629 7 7.68892 7L16.3111 7C17.4437 7 18.1075 8.27496 17.458 9.20285L12.8192 15.8297C12.4211 16.3984 11.5789 16.3984 11.1808 15.8297Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default { TriangleRight, DownArrow };
