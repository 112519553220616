import React, { useEffect, useRef } from "react";
import { Viewer as CesiumViewer } from "cesium";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
// import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../constants";
// import useTilesetsStore from "../stores/tilesets.store";
import useViewStore from "../stores/viewer.store";

// Cesium.Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_TOKEN || "";
Cesium.RequestScheduler.maximumRequestsPerServer = 4;

const CesiumMap = ({ children }: { children?: React.ReactNode }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { viewer, setViewer } = useViewStore();

  // 초기 설정
  useEffect(() => {
    if (divRef.current && !viewer) {
      const v = new CesiumViewer(divRef.current, {
        animation: false,
        timeline: false,
        fullscreenButton: false,
        baseLayerPicker: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        navigationHelpButton: false,
      });

      // const imageryProvider = new Cesium.OpenStreetMapImageryProvider({
      //   url: "https://tile.openstreetmap.org/",
      // });
      // v.imageryLayers.addImageryProvider(imageryProvider);
      setViewer(v);
    }
  }, [setViewer, viewer]);

  // const tilesets = useRecoilValueLoadable(tilesetsSelector);
  const { tilesetMap } = useViewStore();

  useEffect(() => {
    if (viewer && tilesetMap) {
      tilesetMap.forEach((ts) => {
        if (ts && !viewer.scene.primitives.contains(ts)) {
          viewer.scene.primitives.add(ts);
        }
      });
    }
  }, [viewer, tilesetMap]);

  return (
    <div
      ref={divRef}
      style={{ width: "100dvw", height: "100dvh", backgroundColor: "green" }}
    >
      {children && children}
    </div>
  );
};

export default CesiumMap;
