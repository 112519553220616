import { TPhase } from "..";

export type TItem = {
  name: string;
  date: string;
  phase: TPhase;
  modelBeforePath: string;
  modelAfterPath: string;
};

export type TTimelineModeVm = {
  phase: TPhase;
  items: TItem[];
};

const dataBaseUrl = process.env.REACT_APP_DATA_BASE_URL || "";

export const timelineModeVm: TTimelineModeVm = {
  phase: {
    position: [-3051457.7220468684, 4042704.247646128, 3864409.8293511905],
    orientation: {
      heading: 0.313156875998288,
      pitch:-0.27180005163537535,
      roll: 0.000030882813662813646,
    },
  },
  items: [
    {
      name: "이마트 철거",
      date: "2023.08",
      phase: {
        position: [-3051073.4905346646, 4041622.284854461, 3865028.485831244],
        orientation: {
          heading: 0.978823452425793,
          pitch: -0.34326038565677863,
          roll: 0.0000044044506495311,
        },
      },
      modelBeforePath: `${dataBaseUrl}/models/2023/Region16_KP-E/emart_04/tileset.json`,
      modelAfterPath: `${dataBaseUrl}/models/3d-tiles-merged/2024/merged-0/K-HQ/tileset.json`,
      // modelAfterPath: "/models/Manual/K-HQ/tileset.json",
      // modelAfterPath: "/models/3d-tiles-merged/2023/tileset.json",
    },

    {
      name: "삼양비지네스폼 철거",
      date: "2024.01",
      phase: {
        position: [-3051510.9724106956, 4040922.299969804, 3865230.0339388046],
        orientation: {
          heading: 3.891116242212814,
          pitch: -0.4994544177360676,
          roll: 0.000004289985971794863,
        },
      },
      modelBeforePath: `${dataBaseUrl}/models/2023/Region_20_Samyang/tileset.json`,
      modelAfterPath: `${dataBaseUrl}/models/3d-tiles-merged/2024/merged-0/samyang/tileset.json`,
      // modelAfterPath: `${dataBaseUrl}/models/Manual/K-HQ/tileset.json`,
      // modelAfterPath: `${dataBaseUrl}/models/3d-tiles-merged/2023/tileset.json`,
    },
  ],
};

export default { timelineModeVm };
