import { TPhase } from "..";
import modelings from "../modelings.data";

export type TItem = {
  name: string;
  desc: string;
  date: string;
  phase: TPhase;
  modelPath: string;
  phases: {
    name: string;
    desc: string;
    phase: TPhase;
  }[];
};

export type TSpaceModeVm = {
  phase: TPhase;
  items: TItem[];
};

const dataBaseUrl = process.env.REACT_APP_DATA_BASE_URL || "";

export const landingVm: TSpaceModeVm = {
  phase: {
    position: [ -3076123.787318728,
      4078796.354334572,
      3902956.2471823636,],
    orientation: {
      heading: 6.219275859871619,
      pitch: -1.5706878936188335,
      roll: 0.0,
    },
  },
  items: [
    {
      name: "성수클러스터_2차",
      desc: "성수클러스터",
      date: "2024.04",
      modelPath: `${dataBaseUrl}/models/3d-tiles-merged/2024/tileset.json`,
      phase: {
        position: [-3052400.056859965, 4043103.349480272, 3867396.740176105],
        orientation: {
          heading: 0.35310931692087877,
          pitch: -1.5429339140156002,
          roll: 0.0,
        },
      },
      phases: modelings.map((model) => {
        return {
          name: model.name,
          desc: model.desc,
          phase: model.phase,
        };
      }),
    },
  
  ],
};
