import React from "react";
import { Box, Typography } from "@mui/material";
import useCommonDataStore from "../../../stores/common.store";
import useViewStore, { CameraPhase } from "../../../stores/viewer.store";
import { TriangleRight } from "../../../icons";

const FolderMenu = ({ title, msg, phase }: { title: string; msg: string,phase?: CameraPhase }) => {
  const { commonData, setCommonData } = useCommonDataStore();

  const MenuClickEvent = () => {
    if (title === commonData.isActive) {
      setCommonData({ ...commonData, isActive: "" });
    } else {
      setCommonData({ ...commonData, isActive: title });
    }
  };

  const { setPhase } = useViewStore();


  return (
    <div
      className="folderMenuWrap"
      style={{
        display: title === commonData.isActive && commonData.isActive !== "Timeline mode" ? "none" : "block",
      }}
      onClick={() => {
        if (phase)
          setPhase(phase);
        MenuClickEvent();
      }}
      onMouseEnter={(event) => {
        const folderMenuWrap = event.currentTarget as HTMLElement;
        const children = folderMenuWrap.querySelectorAll('*') as NodeListOf<HTMLElement>;
        children.forEach((child) => {
          const modifiedChild = child;
          modifiedChild.style.color = 'yellow';
        });
      }}
      onMouseLeave={(event) => {
        const folderMenuWrap =  event.currentTarget as HTMLElement;
        const children = folderMenuWrap.querySelectorAll('*') as NodeListOf<HTMLElement>;
        children.forEach((child) => {
          const modifiedChild = child;
          modifiedChild.style.color = '';
        });
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >

        <Typography
          key={title}
          sx={{
            fontFamily: "Arimo",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "22px",
            letterSpacing: "-0.43px",
            color: "#FFFFFF",
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: "6px",
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            justifyContent: "space-between",
            position: "relative",
          }}
        >

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              right: "4px",
              borderBottom: "1px dashed white",
              transform: "translateY(-50%)",
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <TriangleRight />
        </Box>
      </Box>
      <Typography
        className={
          commonData.isActive === "" || commonData.isActive !== "Timeline mode" && commonData.isActive === title
            ? ""
            : "hide"
        }
        key={"krafton1"}
        sx={{
          fontFamily: "Arimo",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "12px",
          lineHeight: "22px",
          letterSpacing: "-0.43px",
          color: "#8B8B8B",
        }}
      >
        {msg}
      </Typography>
    </div>
  );
};

export default FolderMenu;
