import { TPhase } from "..";
import modelings, { Model } from "../modelings.data";

type TMasterplanVm = {
  phase: TPhase;
  baseModelPath: string;
  items: Model[];
};

export const masterplanVm: TMasterplanVm = {
  phase: {
    position: [-3052384.7646093355, 4043051.03734328, 3867146.635137534],
    orientation: {
      heading: 0.35270624657094807,
      pitch: -1.520528165780361,
      roll: 6.282541154647833,
    },
  },
  baseModelPath: `${process.env.REACT_APP_DATA_BASE_URL || ""}/models/3d-tiles-merged/2024/tileset.json`,
  items: modelings,
};

export default { masterplanVm };
