import React, { MutableRefObject, useCallback, useEffect, useState } from "react"
import { Box, ButtonBase, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu";
import * as Cesium from "cesium";
import useCommonDataStore from "../stores/common.store";
import useViewStore from "../stores/viewer.store";
import { timelineModeVm, TItem } from "../data/models/timeline-mode.data";
import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../constants";


const hamburgerIconStyle = {
  width: "24px",
  height: "24px",
  display: "flex",
  marginTop: "15px",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "7px 19px 30px 19px",
  marginLeft: "5px",
  borderRadius: "50%",
};

const TimelineBar = () => (
  <Box
    sx={{
      width: '100%',
      height: '17px',
      background: 'linear-gradient(to right, black, white)',
      borderTop: '3px solid white',
      borderBottom: '3px solid #8B8B8B',
      position: 'relative',
      marginTop: '-13px',
      zIndex: '10'
    }}
  />
);

const YellowCircle = () => (
  <Box
    component="span" 
    sx={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'yellow',
      border: '2px solid black',
      display: 'inline-block',
      marginLeft: '10px',
      verticalAlign: 'middle',
    }}
  />
);

const WhiteCircle = () => (
  <Box
    component="span" 
    sx={{
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: 'white',
      border: '2px solid black',
      display: 'inline-block',
      marginLeft: '10px',
      verticalAlign: 'middle',
    }}
  />
);

const timelineFontStyle = {
  textAlign: 'center',
  fontFamily: "Arimo",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "22px",
  letterSpacing: "-0.43px",
  color: "#FFFFFF",
  zIndex:'800',
  marginTop:'-8px',
  flex: '1',
  cursor: 'pointer',
}

const timelineFontStyle2 = {
  textAlign: 'center',
  fontFamily: "Arimo",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "22px",
  letterSpacing: "-0.43px",
  color: "#FFFFFF",
  zIndex:'800',
  marginTop:'-13px',
  flex: '1',
  cursor: 'pointer',
}

const Timeline = ({ sliderRef }: { sliderRef: MutableRefObject<null> }) => {
  const { commonData, setCommonData } = useCommonDataStore();
  const { setTilsets } = useViewStore();
  const { viewer, setPhase } = useViewStore();
  const [selectedItem, setSelectedItem] = React.useState<TItem | null>(null);
  const [moveActive, setMoveActive] = React.useState(false);

  const [handler, setHandler] = useState<Cesium.ScreenSpaceEventHandler | null>(null);


  const onClickEvent = (name: string) => {
    timelineModeVm.items.forEach((item) => {
      if (item.name === name) {
        setSelectedItem(item);
        setCommonData({ ...commonData, slider: true });
      }
    });
  };

  useEffect(() => {
    if (commonData.isActive !== "Timeline mode" && commonData.slider) {
      setCommonData({ ...commonData, slider: false });
      if( sliderRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const currentRef = sliderRef.current as any;
        currentRef.style.left = '50%';
      }
        
    }
    if(commonData.isActive === "Timeline mode"){
      setTilsets([]);
      setSelectedItem(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonData.isActive]);


  useEffect(() => {
    if (viewer) {
      viewer.camera.moveEnd.addEventListener(() => {
        if (viewer && sliderRef.current) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const currentRef = sliderRef.current as any;
          viewer.scene.splitPosition =
            currentRef.offsetLeft / currentRef.parentElement.offsetWidth;
        }
      });
    }
  }, [viewer, sliderRef]);


  useEffect(() => {
    if (sliderRef.current && !handler) {
      const tmp = new Cesium.ScreenSpaceEventHandler(
        sliderRef.current as HTMLCanvasElement,
      );
      setHandler(tmp);
    }
  }, [handler, sliderRef]);


  const move = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (movement: any) => {
      if (!moveActive) return;
      if (!viewer) return;

      const relativeOffset = movement.endPosition.x;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const slider = sliderRef.current as any;
      const splitPosition =
        (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
      slider.style.left = `${100.0 * splitPosition}%`;
      viewer.scene.splitPosition = splitPosition;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [moveActive, viewer],
  );


  useEffect(() => {
    if (handler) {
      handler.setInputAction(() => {
        setMoveActive(true);
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
      handler.setInputAction(() => {
        setMoveActive(false);
      }, Cesium.ScreenSpaceEventType.LEFT_UP);

      handler.setInputAction(move, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      handler.setInputAction(move, Cesium.ScreenSpaceEventType.PINCH_MOVE);

      handler.setInputAction(() => {
        setMoveActive(true);
      }, Cesium.ScreenSpaceEventType.PINCH_START);
      handler.setInputAction(() => {
        setMoveActive(false);
      }, Cesium.ScreenSpaceEventType.PINCH_END);
    }
  }, [handler, move]);

 

  useEffect(() => {
    if (selectedItem) {
      setMoveActive(false);
      setTilsets([
        {
          path: selectedItem.modelBeforePath,
          adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
          splitDirection: Cesium.SplitDirection.LEFT,
        },
        {
          path: selectedItem.modelAfterPath,
          adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
          splitDirection: Cesium.SplitDirection.RIGHT,
        },
      ]);
      setPhase(selectedItem.phase);
      setMoveActive(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const setFolder = (tf: boolean) => {
    setCommonData({ ...commonData, folder: tf });
  }


  const moveToSpaceMode = (idx:string) => {

    setCommonData({ ...commonData, isActive: "Space mode", openIndex: idx });
   

  }





  return (
    <>
      {commonData.isActive === "Timeline mode" && (
        <Box
          sx={{
            zIndex: 999,
            height: '188px',
            width: commonData.folder ? '100%' : 'calc(100% - 265px)',
            marginLeft: commonData.folder ? '0px' : '264px',
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            position: 'absolute',
            borderLeft: commonData.folder ? '' : '1px solid #747474'
          }}
        >
          {commonData.folder ? (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <ButtonBase
                sx={hamburgerIconStyle}
                onClick={() => {
                  setFolder(false);
                }}
              >
                <MenuIcon sx={{ color: "white" }} />
              </ButtonBase>
              <Typography
                key='timeline'
                sx={{
                  fontFamily: "Arimo",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "22px",
                  letterSpacing: "-0.43px",
                  color: "#FFFFFF",
                  paddingLeft: '10px'
                }}
              >
                Timeline mode 
              </Typography>
            </Box>
          ) : (
            <Typography
              key='timeline'
              sx={{
                fontFamily: "Arimo",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "22px",
                letterSpacing: "-0.43px",
                color: "#FFFFFF",
                paddingLeft: '10px',
                paddingTop: '12px',
                height:'40px'
              }}
            >
              Timeline mode 
            </Typography>
          )}
          <Box sx={{ display: 'flex', padding: '0 5%' }}>
            <Typography
              key='timeline1'
              sx={{ 
                ...timelineFontStyle, 
                marginRight: '16%',
                marginLeft:'7%',
                color: selectedItem?.name === "이마트 철거" ? 'yellow' : 'white'
                
              }}
              onClick={() => {
               onClickEvent("이마트 철거");
              }}
            >
              2023.08<br/>이마트 철거
              <br/>
              <YellowCircle/>
            </Typography>
            <Typography
              key='timeline2'
              sx={{ 
                ...timelineFontStyle, 
                marginRight: '2%',
                color: selectedItem?.name === "삼양비지네스폼 철거" ? 'yellow' : 'white'
              }}
              onClick={() => {
                onClickEvent("삼양비지네스폼 철거");
               }}
            >
              2024.01<br/>삼양비지네스폼 철거
              <br/>
              <YellowCircle/>
            </Typography>
            <Typography
              key='timeline3'
              sx={{ 
                ...timelineFontStyle, 
                marginRight: '5%',
                cursor: 'default',
              }}
            >
              2024.01<br/>HQ 착공식
              <br/>
              <YellowCircle/>
            </Typography>
            <Typography
              key='timeline4'
              sx={{ 
                ...timelineFontStyle, 
                marginRight: '15%' ,
                cursor: 'default',
              }}
            >
              2024.08<br/>한일피복 팝업
              <br/>
              <YellowCircle/>
            </Typography>
          </Box>
          <TimelineBar/>
          <Box sx={{ display: 'flex', padding: '0 5%' }}>
            <Typography
              key='timeline2-1'
              sx={{ 
                ...timelineFontStyle2, 
                marginRight: '1%',
                marginLeft:'-6%',
                color: selectedItem?.name === "이마트 철거" ? '#7ed87e' : 'white'

              }}
              onClick={() => {
                moveToSpaceMode("HQ_1차");
              }}
            >
                <WhiteCircle/>
                <br/>2023.04<br/>HQ_1차
            </Typography>
            <Typography
              key='timeline2-2'
              sx={{ 
                ...timelineFontStyle2, 
                marginRight: '1%' ,
                 color: selectedItem?.name === "이마트 철거" ? '#7ed87e' : 'white'
              }}
              onClick={() => {
                moveToSpaceMode("성수클러스터_1차");
              }}
            >
                <WhiteCircle/>
                <br/>2023.09<br/>성수클러스터 1차
            </Typography>
            <Typography
              key='timeline2-3'
              sx={{ 
                ...timelineFontStyle2, 
                marginRight: '3%' ,
                color: selectedItem?.name === "삼양비지네스폼 철거" ? '#7ed87e' : 'white'
              }}
              onClick={() => {
                moveToSpaceMode("C03_1차");
              }}
            >
                <WhiteCircle/>
                <br/>2023.11<br/>C03_1차 
            </Typography>
            <Typography
              key='timeline2-4'
              sx={{ 
                ...timelineFontStyle2, 
                marginRight: '6%' ,
                color: selectedItem?.name === "삼양비지네스폼 철거" ? '#7ed87e' : 'white'
              }}
              onClick={() => {
                moveToSpaceMode("성수클러스터_2차");
              }}
            >
                <WhiteCircle/>
                <br/>2024.04<br/>성수클러스터 2차
            </Typography>
            <Typography
              key='timeline2-5'
              sx={{ 
                ...timelineFontStyle2, 
                marginRight: '6%'
              }}
              onClick={() => {
                moveToSpaceMode("X01_1차");
              }}
            >
                <WhiteCircle/>
                <br/>2024.05<br/>X01_1차
            </Typography>
            <Typography
              key='timeline2-6'
              sx={{ 
                ...timelineFontStyle2, 
                marginRight: '0%',
                cursor: 'default',
              }}
              // onClick={() => {
              //   moveToSpaceMode("");
              // }}
            >
                <WhiteCircle/>
                <br/>2025.01<br/>성수클러스터 3차
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Timeline;