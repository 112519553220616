import { TPhase, TPin } from "..";

type TKraptonVm = {
  phase: TPhase;
  pins: TPin[];
};

export const kraptonVm: TKraptonVm = {
  phase: {
    position: [-3076123.787318728, 4078796.354334572, 3902956.2471823636],
    orientation: {
      heading: 6.219275859871619,
      pitch: -1.5706878936188335,
      roll: 0.0,
    },
  },
  pins: [
    { name: "(주)크래프톤", position: [127.0415, 37.50325], zoom: 255 },
    { name: "크래프톤 펍지 스튜디오", position: [127.0054, 37.4905], zoom: 255 },
    { name: "라이징윙스㈜", position: [127.0651, 37.5072], zoom: 255 },
    { name: "㈜블루홀스튜디오", position: [127.112, 37.39426], zoom: 255 },
    { name: "㈜드림모션", position: [127.122, 37.38673], zoom: 255 },
    { name: "㈜띵스플로우", position: [127.0626, 37.54206], zoom: 255 },
    { name: "㈜팁토우게임즈", position: [127.0057, 37.4902], zoom: 255 },
    { name: "㈜5민랩", position: [126.9112, 37.54915], zoom: 255 },
    { name: "㈜렐루게임즈", position: [127.1083, 37.34955], zoom: 255 },
    { name: "㈜플라이웨이게임즈", position: [127.0298, 37.49208], zoom: 255 },
    {
      name: "En Masse Entertainment, Inc.",
      position: [-122.196, 47.6151],
      zoom: 255,
    },
    { name: "Krafton Americas, Inc.", position: [-118.473, 34.02819], zoom: 255 },
    { name: "KRAFTON EUROPE B.V.", position: [4.942842, 52.30711], zoom: 255 },
    { name: "KRAFTON CHINA", position: [121.4979, 31.24869], zoom: 255 },
    { name: "PUBG MadGlory, LLC", position: [-89.3839, 43.07309], zoom: 255 },
    { name: "KP PTE. LTD.", position: [103.8373, 1.300217], zoom: 255 },
    {
      name: "Striking Distance Studios, Inc.",
      position: [-121.955, 37.75906],
      zoom: 5,
    },
  ],
};

export default { kraptonVm };
