import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import * as Cesium from "cesium";
import useCommonDataStore from "../../stores/common.store";
import { DownArrow } from "../../icons/index";
import { kraptonVm } from "../../data/k-projects/krafton.data";

import { TPin } from "../../data";
import useViewStore from "../../stores/viewer.store";


const svgContent = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_333_334)">
<path d="M26.25 12.5C26.25 21.25 15 28.75 15 28.75C15 28.75 3.75 21.25 3.75 12.5C3.75 9.51631 4.93526 6.65483 7.04505 4.54505C9.15483 2.43526 12.0163 1.25 15 1.25C17.9837 1.25 20.8452 2.43526 22.955 4.54505C25.0647 6.65483 26.25 9.51631 26.25 12.5Z" stroke="#F3F3F3" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 16.25C17.0711 16.25 18.75 14.5711 18.75 12.5C18.75 10.4289 17.0711 8.75 15 8.75C12.9289 8.75 11.25 10.4289 11.25 12.5C11.25 14.5711 12.9289 16.25 15 16.25Z" stroke="#F3F3F3" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_333_334">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>
`;

const backgroundStyle = {
  borderRadius: "5px",
  border: "solid 1px white",
  marginBottom: "8px",
};

const svgUrl = `data:image/svg+xml;base64,${btoa(svgContent)}`;




const Krafton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { commonData, setCommonData } = useCommonDataStore();
  const { viewer, setPhase } = useViewStore();
  const [val, setVal] = useState<string>(''); 
  const { setTilsets } = useViewStore();

  useEffect(() => {
    if(viewer && val!==''){
      const position = JSON.parse(val);
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(position[0], position[1], 255), // 줌 인할 위치와 높이 설정
        orientation: {
            heading: Cesium.Math.toRadians(0.0),
            pitch: Cesium.Math.toRadians(-90.0),
            roll: 0.0
        }
      });

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer, val]);


  useEffect(() => {
    if (commonData.isActive !== "krafton") {
      setIsOpen(false);
      setVal('');
    }else{
      setPhase(kraptonVm.phase);
      setTilsets([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonData.isActive]);

  const toggleDropdown = () => {
    setCommonData({ ...commonData, isActive: !isOpen ? "krafton" : "" });
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if(viewer){
    if(commonData.isActive==="krafton"){
      kraptonVm.pins.forEach((p) => {
        viewer.entities.add({
          name: p.name,
          position: Cesium.Cartesian3.fromDegrees(...p.position),
          billboard: {
              image: svgUrl,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM
          },
          properties: p
        });
      });
    } else {
      viewer.entities.removeAll();
    }}
  }, [viewer, commonData.isActive]);

  const [pinClickHandler, setClickHandler] = React.useState<Cesium.ScreenSpaceEventHandler | null>(null);

  useEffect(() => {
    if(viewer)
      setClickHandler(new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas));
  }, [viewer]);



  useEffect(() => {
    if(pinClickHandler&&viewer){
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pinClickHandler.setInputAction((click:any) => {
          const pickedObject = viewer.scene.pick(click.position);
          // console.log(pickedObject.primitive);
          
          if(pickedObject){
            // eslint-disable-next-line prefer-destructuring
            try{
              const {properties} = pickedObject.id;
    
            if(properties){
              const p = properties.getValue("name") as TPin;
              setVal(JSON.stringify(p.position));
              viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(...p.position, p.zoom), // 줌 인할 위치와 높이 설정
                orientation: {
                    heading: Cesium.Math.toRadians(0.0),
                    pitch: Cesium.Math.toRadians(-90.0),
                    roll: 0.0
                }
              });
            }
            // eslint-disable-next-line no-empty, @typescript-eslint/no-unused-vars
            }catch(e){
            }
     
          }
          
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinClickHandler]);


  return (
    <Box sx={backgroundStyle}>
      <Button
        sx={{
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
          color: isOpen ? "yellow" : "white",
        }}
        onClick={toggleDropdown}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            color: isOpen ? "yellow" : "white",
          }}
        >
          <Typography
            sx={{
              size: "14px",
              lineHeight: "22px",
              letterSpacing: "-0.43px",
              fontWeight: "700",
            }}
          >
            크래프톤 독립스튜디오
          </Typography>
          <DownArrow
            sx={{ marginLeft: "auto", width: "30px", height: "30px" }}
          />
        </Box>
        <Typography sx={{ fontSize: "10px" }}>(2024.08)</Typography>
      </Button>
      {isOpen && (
        <Box>
          <FormControl size="small" sx={{ m: 1, minWidth: 224 }}>
            <Select
              id="demo-select-small"
              sx={{
                fontSize: "10px",
                padding: "0px",
                color: "white",
                borderRadius: "5px",
                border: "solid 0.3px",
              }}
              value={val}
              onChange={(event: SelectChangeEvent<string>) => {
                setVal(event.target.value);
              }}
            >
              {kraptonVm.pins.map((pin: TPin) => (
                <MenuItem key={pin.name} value={JSON.stringify(pin.position)}>
                  {pin.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default Krafton;