import { TPhase } from ".";

export type Model = {
  name: string;
  desc: string;
  phase: TPhase;
  modelPath: string;
  info?: { name: string; script: string; facil: string; outline: { adress: string; area: string; scale: string; total: string; ground: string; capacity: string; per: string; } };
};

const dataBaseUrl = process.env.REACT_APP_DATA_BASE_URL || "";

export const modelings: Model[] = [
  {
    name: "HQ",
    desc: "이마트",
    phase: {
      position: [-3051073.4905346646, 4041622.284854461, 3865028.485831244],
      orientation: {
        heading: 0.978823452425793,
        pitch: -0.34326038565677863,
        roll: 0.0000044044506495311,
      },
    },
    info:{
      name:"크래프톤 글로벌 HQ",
      script: "HQ 및 HQ와 현업 강도 높음<br/>클러스터 전체 공유 부대시설 설치",
      facil: "HQ 조직<br/>글로벌 IP프랜차이즈 서비스 (300명 이상)",
      outline:{
        adress : "성수일로",
        area: "20,810.00㎡",
        scale:"지하8층, 지상17층",
        total:"223,106.52㎡",
        ground:"114,950.43㎡",
        capacity:"3,502명 / 436명",
        per:  "3.84평"
      }
    },
    modelPath: `${dataBaseUrl}/models/Manual/K-HQ/tileset.json`,
  },
  {
    name: "C01",
    desc: "메가박스",
    phase: {
      position: [-3050477.3333964134, 4041792.3826188436, 3865228.704041293],
      orientation: {
        heading: 0.997021376354188,
        pitch: -0.4040719271035149,
        roll: 0.0000025589238648038304,
      },
    },
    info:{
      name:"메가박스",
      script: "대중교통 접근성 우수<br/>외부 협력 및 팬 교류시설 배치에 유리",
      facil: "업계 대외 교류 및 외부 콘텐츠 협업 빈도가 높은 조직<br/>팬 교류 니즈가 있는 조직. 외부 합작투자 조직",
      outline:{
        adress : "서울 성동구 왕십리로 50",
        area: "3,441.00㎡ ",
        scale:"지하5층, 지상8층",
        total:"25,633.54㎡",
        ground:"12,694.54㎡",
        capacity:"743명 / 191명",
        per:  "2.50평"
      }
    },
    modelPath: `${dataBaseUrl}/models/Manual/megabox/tileset.json`,
  },
  {
    name: "C02",
    desc: "고물상",
    phase: {
      position: [-3051362.9455147428, 4041000.3569180337, 3865309.0401933133],
      orientation: {
        heading: 2.696565633663491,
        pitch: -0.5844828239882336,
        roll: 6.283184763713004,
      },
    },
    info:{
      name:"고물상",
      script: "HQ와의 거리가 가장 가까움, 부대시설 공유 가능<br/>성수동 연무장길과 근접",
      facil: " HQ와 소통.교류 많은 조직<br/>소규모 스튜디오, 신규 분사조직, 신작개발조직",
      outline:{
        adress : "서울특별시 성동구 성수이로14길 8",
        area: "606.60㎡",
        scale:"지하2층, 지상7층",
        total:"3,422.00㎡",
        ground:"2,422.00㎡",
        capacity:"189명 / 31명",
        per:  "2.50평"
      }
    },
    modelPath: `${dataBaseUrl}/models/Manual/gms/tileset.json`,
  },
  {
    name: "C03",
    desc: "삼양비지네스폼",
    phase: {
      position: [-3051526.9724106956, 4040922.299969804, 3865250.0339388046],
      orientation: {
        heading: 3.891116242212814,
        pitch: -0.4994544177360676,
        roll: 0.000004289985971794863,
      },
    },
    info:{
      name:"삼양비지네스폼",
      script: "HQ와의 긴밀한 협업<br/>HQ가 라이브서비스를 담당. 부대시설 공유 가능",
      facil: " SLC<br/>Work for Hire",
      outline:{
        adress : "서울특별시 성동구 성수동2가 322-6",
        area: "1,292,60㎡",
        scale:"지하2층, 지상9층",
        total:"7,821.27㎡",
        ground:"6,066.97㎡",
        capacity:"423명 / 78명",
        per:  "2.50평"
      }
    },
    modelPath: `${dataBaseUrl}/models/Manual/samyang/tileset.json`,
  },
  {
    name: "C04",
    desc: "대륭덕성",
    phase: {
      position: [-3051894.8388927514, 4040541.368282577, 3865428.3400773406],
      orientation: {
        heading: 4.235651875068908,
        pitch: -0.41181520848340636,
        roll: 0.000003943667059580491,
      },
    },
    info:{
      name:"대륭덕성",
      script: "HQ 다음 규모, 개발자 거점 역할 가능<br/>게임 개발자의 교류 허브",
      facil: "소규모 ~ 300명 정도의 대규모 조직까지 다양한 규모<br/>2기 특수시설의 집중 배치",
      outline:{
        adress : "서울특별시 성동구 성수이로20길 51",
        area: "4,776.90㎡",
        scale:"지하8층, 지상10층",
        total:"62,815.00㎡",
        ground:"28,415.00㎡",
        capacity:"1,407명 / 197명",
        per:"3.30평"
      }
    },
    modelPath: `${dataBaseUrl}/models/Manual/drds/tileset.json`,
  },
  {
    name: "C05",
    desc: "성동마트",
    phase: {
      position: [-3051990.738605672, 4041114.214480579, 3864761.836097726],
      orientation: {
        heading: 5.80295449176542,
        pitch: -0.2858886959824667,
        roll: 0.000005703064439366301,
      },
    },
    info:{
      name:"성동마트",
      script: "공동개발 이슈, 복합개발 검토 이슈<br/>개발 유보",
      facil: ".",
      outline:{
        adress : "서울특별시 성동구 뚝섬로 447",
        area: "6,353.00㎡",
        scale:"지하5층, 지상13층",
        total:"56,940.14㎡",
        ground:"30,440.14㎡",
        capacity:"1,508명 / 190명",
        per:"3.30평"
      }
    },
    modelPath: `${dataBaseUrl}/models/Manual/sdmart/tileset.json`,
  },
  {
    name: "C06",
    desc: "동흥",
    phase: {
      position: [-3051648.8450032817, 4040426.788990461, 3865866.6592581384],
      orientation: {
        heading: 2.875530141114548,
        pitch: -0.6924270784921438,
        roll: 6.283185075893292,
      },
    },
    info:{
      name:"동흥빌딩",
      script: "HQ 거리 이슈, 오피스 활용성 떨어짐<br/>개발 유보",
      facil: ".",
      outline:{
        adress : "서울특별시 성동구 광나루로6길 42",
        area: "1,472.10㎡",
        scale:"지하6층, 지상10층",
        total:"15,449.14㎡",
        ground:"8,200.14㎡",
        capacity:"566명 / 80명",
        per:"2.5 0평"
      },},
    modelPath: `${dataBaseUrl}/models/Manual/dhbuilding/tileset.json`,
  },
  {
    name: "X01",
    desc: "한일피복",
    phase: {
      position: [-3050485.1133681866, 4041355.9834437915, 3865550.354248671],
      orientation: {
        heading: 5.616613392225446,
        pitch: -0.3687117562623716,
        roll: 2.773357756780115e-7,
      },
    },
    modelPath: "",
  },
];

export default modelings;
