import React, { useEffect } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import * as Cesium from "cesium";
import FolderMenu from "./sideMenu/FolderMenu";
import SpaceMode from "./sideMenu/SpaceMode";
// import SearchBox from "./sideMenu/SearchBox";
import useCommonDataStore from "../../stores/common.store";
import MasterPlan from "../kproject/MasterPlan";
import { spaceModeVm } from "../../data/models/space-mode.data";
import Krafton from "../kproject/Krafton";
import { timelineModeVm } from "../../data/models/timeline-mode.data";
import useViewStore from "../../stores/viewer.store";
import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../../constants";
import { landingVm } from "../../data/models/landing.data";

const buttonStyle = {
  color: "#FFFFFF",
  borderColor: "#FFFFFF",
  borderRadius: "10px",
  width: "100%",
  justifyContent: "flex-start",
  fontFamily: "Arimo",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "22px",
  alignItems: "center",
  letterSpacing: "-0.43px",
  boxSizing: "border-box",
  border: "1px solid #FFFFFF",
  height: "24px",
  paddingX: "12px",
};

const hamburgerIconStyle = {
  width: "24px",
  height: "24px",
  display: "flex",
  marginTop: "15px",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  padding: "7px 19px 30px 19px",
  marginLeft: "5px",
  borderRadius: "50%",
};

const titleStyle = {
  width: "100%",
  marginTop: "24px",
  fontFamily: "Arimo",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "30px",
  lineHeight: "22px",
  letterSpacing: "-0.43px",
  color: "#747474",
  cursor: "pointer",
};

export default function Drawer() {
  // const [folder, setFolder] = useState<boolean>(false);
  const { commonData, setCommonData } = useCommonDataStore();
  const { viewer } = useViewStore();
  const { setTilsets } = useViewStore();


  const setFolder=(tf:boolean)=>{
    setCommonData({ ...commonData, folder: tf});
  }
  
// eslint-disable-next-line spaced-comment
//스페이스모드 메뉴 선택
  const setOpenIndex = (idx: string | null) => { 
    setCommonData({ ...commonData, openIndex: idx});
  }

  const resetMenu=()=>{
    setCommonData({ ...commonData, isActive: ''});
  }

  const handleToggle = (index: string) => {
    setOpenIndex((commonData.openIndex === index ? null : index));
  };


  const resetMap=()=>{
    if (viewer) {
      const position = new Cesium.Cartesian3(
       ...landingVm.phase.position
      );
      viewer.camera.flyTo({
        duration: 1,
        destination: position,
        orientation: landingVm.phase.orientation,
      });


      setTilsets([
        {
          path: landingVm.items[0].modelPath,
          adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
        },
      ]);

    }

  }


  useEffect(() => {
    if(commonData.isActive !== "Space mode" &&commonData.openIndex){
      setOpenIndex(null);

    }
  },[commonData.isActive, commonData.openIndex])

  useEffect(() => {
    if(viewer){
      resetMap();
    }
  },[viewer])


  return (
    <div style={{ position: "absolute", top: 0, left: 0 }}>
      {!commonData.folder ? null : (
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
        >
          <ButtonBase
            sx={hamburgerIconStyle}
            onClick={() => {
              setFolder(false);
            }}
          >
            <MenuIcon sx={{ color: "white" }} />
          </ButtonBase>
        </Box>
      )}
      <Box
        sx={{
          color: "white",
          width: 240,
          height: "100dvh",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          flexDirection: "column",
          alignItems: "center",
          paddingX: "12px",
          minHeight: "500px",
          overflowY: "auto",
          msOverflowStyle: "none", // 스크롤 숨김
          scrollbarWidth: "none", // 스크롤 숨김
          display: commonData.folder ? "none" : "flex"
        }}
      >
        {/* ------- 헤더 Start ------- */}
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
        >
          <ButtonBase
            sx={{
              width: "24px",
              height: "24px",
              display: "flex",
              paddingTop: "22px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setFolder(true);
            }}
          >
            <MenuIcon sx={{ color: "white" }} />
          </ButtonBase>
          <Typography key={"typo"} sx={titleStyle} 
          onClick={()=>{resetMenu();resetMap();}}>
            &nbsp;Spatial Log
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            backgroundColor: "#747474",
            marginY: "16px",
          }}
        />
        {/* ------- 헤더 End ------- */}

        {/* ------- K-project Start ------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
            width: "100%",
            ...(commonData.isActive === "krafton" ||
            commonData.isActive === "masterPlan" ||
            commonData.isActive === "master"
              ? { height: "100%" }
              : {}),
          }}
        >
          <Typography
            key={"k-project"}
            sx={{
              fontFamily: "Arimo",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "22px",
              letterSpacing: "-0.43px",
              color: "#FFFFFF",
            }}
          >
            K-project
          </Typography>
          {/* <SearchBox /> */}
          <Krafton />
          <MasterPlan />
        </Box>
        {/* ------- K-project End ------- */}

        <Box
        sx={{

          minHeight:'10px',
          height:'10%',
          width:'100%'
        }}
        ></Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
           
            rowGap: "16px",
            width: "100%",
          }}
        >
          {commonData.isActive !== "Timeline mode" && (
            <>
              <Typography
                key={"Archives"}
                sx={{
                  // marginTop: "24px",
                  fontFamily: "Arimo",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "22px",
                  letterSpacing: "-0.43px",
                  color: "#FFFFFF",
                }}
                onClick={()=>{resetMenu();}}
                
              >
                {commonData.isActive === "" || commonData.isActive === "masterPlan" || commonData.isActive === "krafton"? "Archives" : commonData.isActive}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "white",
                }}
              />
            </>
          )}
          <FolderMenu
            msg="Krafton의 공간정보를 공간의 이동에 따라 보는 모드"
            phase={spaceModeVm.phase}
            title="Space mode"
          />
          <div
            style={{ width: "100%",
                    display: commonData.isActive !== "Space mode" ? "none" : "block" }}
          >
            {spaceModeVm.items.map((project) => (
              <SpaceMode
                isOpen={commonData.openIndex === project.name}
                key={project.name}
                project={project}
                onToggle={() => handleToggle(project.name)}
              />
            ))}
          </div>
          <FolderMenu
            msg="Krafton의 공간정보를 시간의 흐름에 따라 보는 모드"
            phase={timelineModeVm.phase}
            title="Timeline mode"

          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <ButtonBase sx={{ ...buttonStyle, marginBottom: "10px" }}>
          Admin page
        </ButtonBase>
      </Box>
    </div>
  );
}