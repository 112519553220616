import React, { useEffect } from "react";
import useCommonDataStore from "../../../stores/common.store";
import { spaceModeVm } from "../../../data/models/space-mode.data";

const SpaceModePopup = () => {
  const { commonData } = useCommonDataStore();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = React.useState<any>(null);

const handleCellClick = (link: string | null) => {
    if(link) window.open(link, '_blank');
};

  useEffect(() => {
    if (commonData.isActive === "Space mode") {
      const dt = spaceModeVm.items.filter(
        (item) => item.name === commonData.openIndex,
      );

      if (dt.length > 0) {
        setData(dt[0]);
      }
    } else {
      setData(null);
    }
  }, [commonData.openIndex, commonData.isActive]);

  return (
    <div
      className="space-mode-popup"
      style={{
        position: "absolute",
        zIndex: 9999,
        top: "50px",
        right: 0,
        display: data ? "block" : "none",
      }}
    >
      <div
        className="container"
        style={{
          width: "240px",
          padding: "10px",
          fontSize: "10px",
          boxSizing: "border-box",
          borderRadius: "10px",
          color: "white",
          background: "rgba(0, 0, 0, 0.75)",
          right: "0",
          position: "absolute",
        }}
      >
        <div className="content-box">
          <h2 style={{marginLeft:"4px"}}>{data ? data.name : ""}</h2>
          <table
            style={{
              fontFamily: "sans-serif",
              fontWeight: "bold",
              tableLayout: "fixed",
              borderCollapse: "separate",
              borderSpacing: "5px",
            }}
          >
            <colgroup>
              <col style={{ width: "40%" }} />
              <col style={{ width: "60%" }} />
            </colgroup>
            <tbody>
              <tr>
                <td>취득 시작 일자</td>
                <td>{data ? data.data.startDt : null}</td>
              </tr>
              <tr>
                <td>취득 종료 일자</td>
                <td>{data ? data.data.EndDt : null}</td>
              </tr>
              <tr>
                <td>데이터 구분</td>
                <td>{data ? data.data.dtType : null}</td>
              </tr>
              <tr>
                <td>유형</td>
                <td>{data ? data.data.type : null}</td>
              </tr>
              <tr>
                <td>장소</td>
                <td>{data ? data.data.place : null}</td>
              </tr>
              <tr>
                <td>내용</td>
                <td>{data ? data.data.detail : null}</td>
              </tr>
              <tr>
                <td>발행 일자</td>
                <td>{data ? data.data.pbDate : null}</td>
              </tr>
              <tr>
                <td>파일 형식</td>
                <td>{data ? data.data.fileType : null}</td>
              </tr>
              <tr>
                <td>용량(GB)</td>
                <td>{data ? data.data.voulume : null}</td>
              </tr>
              <tr>
                <td>수량(개수)</td>
                <td>{data ? data.data.count : null}</td>
              </tr>
              <tr>
                <td>다운로드 링크</td>
                <td 
                style={{cursor:"pointer"}}
                onClick={()=>{
                    const a = data?data.data.url:null;
                    handleCellClick(a);
                }}>
                    {data ? data.data.link : null}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SpaceModePopup;
