/* eslint-disable spaced-comment */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import * as Cesium from "cesium";
import useCommonDataStore from "../../stores/common.store";
import { DownArrow } from "../../icons";
import { masterplanVm } from "../../data/k-projects/masterplan.data";
import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../../constants";
import useViewStore from "../../stores/viewer.store";
import "../../styles/css/cesium.css";

const MasterPlan = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { commonData, setCommonData } = useCommonDataStore();
  const { viewer } = useViewStore();
  const [val, setVal] = useState<string>(""); //카메라 위치값

  const { setPhase } = useViewStore();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [updatedTilesetMap, setUpdatedTilesetMap] = useState<null | any>(null); //타일셋 종류
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [clickedFeature, setClickedFeature] = useState<null | any>(null); //클릭한 피처

  const sYellow = Cesium.PostProcessStageLibrary.createEdgeDetectionStage();
  sYellow.uniforms.color = Cesium.Color.YELLOW;
  sYellow.uniforms.length = 0.01;
  sYellow.selected = [];

  const closeInfoBox=()=> {
    if(viewer){
      viewer.infoBox.frame.style.display = "none";
    }
  }
    

  const nameOverlay: HTMLElement = document.createElement("div");
  if (viewer) {
    viewer.container.appendChild(nameOverlay);
  }
  nameOverlay.className = "backdrop";
  nameOverlay.style.display = "none";
  nameOverlay.style.position = "absolute";
  nameOverlay.style.bottom = "0";
  nameOverlay.style.left = "0";
  nameOverlay.style.pointerEvents = "none";
  nameOverlay.style.padding = "4px";
  nameOverlay.style.backgroundColor = "black";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function createPickedFeatureDescription(data: any) {
    const description = `
        <div>
          <h2>${data ? data.name : null}_${data ? data.info.name : null}</h2>
         
            <p>• 특징</p>
            <p style="margin-left:7px"> ${data ? data.info.script : null} </p>
            <p>• 입주대상 조직 및 시설</p>
             <p style="margin-left:7px"> ${data ? data.info.facil : null}  </p>
            <p>• 개요</p>

              <table  style="font-size: 10px; margin-left:7px">
             <tr><td>주소</td><td>${data ? data.info.outline.adress : null}</td></tr>
             <tr><td>대지면적</td><td>${data ? data.info.outline.area : null}</td></tr>
              <tr><td>건축규모</td><td>${data ? data.info.outline.scale : null}</td></tr>
             <tr><td>전체 연면적</td><td>${data ? data.info.outline.total : null}</td></tr>
             <tr><td>지상 연면적</td><td>${data ? data.info.outline.ground : null}</td></tr>
              <tr><td>수용가능인원</td><td>${data ? data.info.outline.capacity : null}</td></tr>
             <tr><td>1인당 업무면적</td><td>${data ? data.info.outline.per : null}</td></tr>
             </table>
         
            <p>• S&P Records</p>
            <p style="margin-left:7px">Link</p>
            <p style="margin-left:7px">Link</p>
          
        </div>
      `;

    return `
        <header>
          <style>
            .content-box {
              width: 240px;
              padding: 10px;
              font-size: 10px;
              box-sizing: border-box;
              border-radius: 10px;
              color: white;
              background: rgba(0, 0, 0, 0.75);
              right: 0;
              position: absolute;
            }
          </style>
        </header>
        <div class="container">
          <div class="content-box">
           ${description}
          </div>
        </div>
      `;
  }

  useEffect(() => {
    if (clickedFeature) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filteredTileset = updatedTilesetMap?.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (tileset: any) => tileset.path === clickedFeature,
      );
      if (filteredTileset) {
        if(viewer) viewer.infoBox.frame.style.display = "";
        masterplanVm.items.forEach((item) => {
          if (item.modelPath === filteredTileset.path) {
            setVal(JSON.stringify(item.phase));
          }
        });
      }
    }
  }, [clickedFeature]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (viewer) {
      const selected = {
        feature: undefined,
        originalColor: new Cesium.Color(),
        name: null,
      };

      viewer.scene.postProcessStages.add(
        Cesium.PostProcessStageLibrary.createSilhouetteStage([sYellow]),
      );

      const clickHandler = viewer.screenSpaceEventHandler.getInputAction(
        Cesium.ScreenSpaceEventType.LEFT_CLICK,
      );

      // 클릭 이벤트 핸들러 추가
      viewer.screenSpaceEventHandler.setInputAction(
        (movement: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
          // 이전에 선택된 피처의 색상을 원래대로 돌립니다.
          if (Cesium.defined(selected.feature)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (selected.feature as any).color = Cesium.Color.WHITE;
            selected.feature = undefined;
          }

          // 새로운 피처를 선택합니다.
          const pickedFeature = viewer.scene.pick(movement.position);
          if (Cesium.defined(pickedFeature)) {
            try {
              // eslint-disable-next-line no-underscore-dangle
              setClickedFeature(pickedFeature._content._tile._tileset._url);
              selected.feature = pickedFeature;

              // pickedFeature.color = Cesium.Color.YELLOW; // 하이라이트 색상

              Cesium.Color.clone(pickedFeature.color, selected.originalColor);
              sYellow.selected = [pickedFeature];

              try {
                viewer.selectedEntity = pickedFeature;
                // eslint-disable-next-line no-underscore-dangle
                const url = pickedFeature._content._tile._tileset._url;

                masterplanVm.items.forEach((item) => {
                  if (item.modelPath === url) {
                    if (viewer.selectedEntity) {
                      viewer.selectedEntity.description =
                        new Cesium.ConstantProperty(
                          createPickedFeatureDescription(item),
                        );
                    }
                  }
                });

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
              } catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                clickHandler(movement as any);
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (e) {
              // eslint-disable-next-line no-useless-return
              return;
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            clickHandler(movement as any); // 타입 캐스팅을 any로 변경
          }
        },
        Cesium.ScreenSpaceEventType.LEFT_CLICK,
      );

      // 필요한 경우 정리 함수 추가
      return () => {
        viewer.screenSpaceEventHandler.removeInputAction(
          Cesium.ScreenSpaceEventType.LEFT_CLICK,
        );
      };
    }
  }, [viewer]);

  const { setTilsets } = useViewStore();

  const setTileset = async () => {
    const paths = [];
    paths.push({
      path: masterplanVm.baseModelPath,
      adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
    });
    masterplanVm.items.forEach((item) => {
      if (item.modelPath && item.modelPath !== "") {
        paths.push({ path: item.modelPath, name: item.name });
      }
    });

    setTilsets(paths);
    setUpdatedTilesetMap(paths);
  };

  useEffect(() => {
    if (isOpen) {
      setTileset();
    } else {
      setVal("");
    }
  }, [isOpen, setTilsets]);

  useEffect(() => {
    if (commonData.isActive !== "masterPlan") {
      setIsOpen(false);
      setClickedFeature(null);
      closeInfoBox();
      sYellow.selected = [];
    } else {
      setPhase(masterplanVm.phase);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonData.isActive]);

  useEffect(() => {
    if (val) {
      setPhase(JSON.parse(val));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  const toggleDropdown = () => {
    setCommonData({ ...commonData, isActive: !isOpen ? "masterPlan" : "" });
    setIsOpen(!isOpen);
  };

  const backgroundStyle = {
    borderRadius: "5px",
    border: "solid 1px white",
    marginBottom: "8px",
  };

  return (
    <Box sx={backgroundStyle}>
      <Button
        sx={{
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
          color: isOpen ? "yellow" : "white",
        }}
        onClick={toggleDropdown}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Typography
            sx={{
              size: "14px",
              lineHeight: "22px",
              letterSpacing: "-0.43px",
              fontWeight: "700",
            }}
          >
            K-성수 마스터플랜
          </Typography>
          <DownArrow
            sx={{ marginLeft: "auto", width: "30px", height: "30px" }}
          />
        </Box>
        <Typography sx={{ fontSize: "10px" }}>(2024.08)</Typography>
      </Button>
      {isOpen && (
        <Box>
          <FormControl size="small" sx={{ m: 1, minWidth: 224 }}>
            <Select
              id="demo-select-small"
              sx={{
                fontSize: "10px",
                padding: "0px",
                color: "white",
                borderRadius: "5px",
                border: "solid 0.3px",
              }}
              value={val}
              onChange={(event: SelectChangeEvent<string>) => {
                setVal(event.target.value);
              }}
            >
              {masterplanVm.items.map((item, index) => (
                <MenuItem key={index} value={JSON.stringify(item.phase)}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default MasterPlan;
