import { create } from "zustand";

type TCommonData = {
  isActive: string; // krafton, masterPlan, Space mode, TimeLine mode
  folder : boolean;
  slider : boolean;
  openIndex: string | null;
};

const useCommonDataStore = create<{
  commonData: TCommonData;
  setCommonData: (data: TCommonData) => void;
}>((set) => ({
  commonData: {
    isActive: "",
    folder:false,
    slider:false,
    openIndex: null,
  },
  setCommonData: (data) => set({ commonData: data }),
}));

export default useCommonDataStore;
