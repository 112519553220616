import React, { useCallback, useEffect } from "react";
import { Box, Button } from "@mui/material";
import styled from "styled-components"; // eslint-disable-line import/no-extraneous-dependencies
import * as Cesium from "cesium";
import CesiumMapDev from "../../components/CesiumMap.dev";
import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../../constants";
import useViewStore from "../../stores/viewer.store";
import { timelineModeVm } from "../../data/models/timeline-mode.data";

const Slider = styled.div`
  position: absolute;
  left: 50%;
  top: 0px;
  background-color: #d3d3d3;
  width: 5px;
  height: 100%;
  z-index: 9999;

  &:hover {
    cursor: ew-resize;
  }
`;

export default function TimelineModePage() {
  const { viewer, setPhase } = useViewStore();

  useEffect(() => {
    setPhase(timelineModeVm.phase);
  }, [setPhase]);

  const [selectedItem, setSelectedItem] = React.useState(
    timelineModeVm.items[0],
  );

  const { setTilsets } = useViewStore();
  const [moveActive, setMoveActive] = React.useState(false);
  useEffect(() => {
    if (selectedItem) {
      setMoveActive(false);
      setTilsets([
        {
          path: selectedItem.modelBeforePath,
          adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
          splitDirection: Cesium.SplitDirection.LEFT,
        },
        {
          path: selectedItem.modelAfterPath,
          adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
          splitDirection: Cesium.SplitDirection.RIGHT,
        },
      ]);
      setPhase(selectedItem.phase);
      setMoveActive(true);
    }
  }, [selectedItem, setPhase, setTilsets]);

  const sliderRef = React.useRef(null);

  useEffect(() => {
    if (viewer) {
      viewer.camera.moveEnd.addEventListener(() => {
        if (viewer && sliderRef.current) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const currentRef = sliderRef.current as any;
          viewer.scene.splitPosition =
            currentRef.offsetLeft / currentRef.parentElement.offsetWidth;
        }
      });
    }
  }, [viewer, sliderRef]);

  // useEffect(() => {
  //   if (viewer && sliderRef.current) {
  //     const currentRef = sliderRef.current as HTMLDivElement;
  //     viewer.scene.splitPosition = currentRef.offsetLeft / currentRef.offsetWidth;
  //   }
  // }, [viewer, sliderRef]);

  const [handler, setHandler] =
    React.useState<Cesium.ScreenSpaceEventHandler | null>(null);

  useEffect(() => {
    if (sliderRef.current && !handler) {
      const tmp = new Cesium.ScreenSpaceEventHandler(
        sliderRef.current as HTMLCanvasElement,
      );
      setHandler(tmp);
    }
  }, [handler, sliderRef]);

  const move = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (movement: any) => {
      if (!moveActive) return;
      if (!viewer) return;

      const relativeOffset = movement.endPosition.x;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const slider = sliderRef.current as any;
      const splitPosition =
        (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
      slider.style.left = `${100.0 * splitPosition}%`;
      viewer.scene.splitPosition = splitPosition;
    },
    [moveActive, viewer],
  );

  useEffect(() => {
    if (handler) {
      handler.setInputAction(() => {
        setMoveActive(true);
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
      handler.setInputAction(() => {
        setMoveActive(false);
      }, Cesium.ScreenSpaceEventType.LEFT_UP);

      handler.setInputAction(move, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      handler.setInputAction(move, Cesium.ScreenSpaceEventType.PINCH_MOVE);

      handler.setInputAction(() => {
        setMoveActive(true);
      }, Cesium.ScreenSpaceEventType.PINCH_START);
      handler.setInputAction(() => {
        setMoveActive(false);
      }, Cesium.ScreenSpaceEventType.PINCH_END);
    }
  }, [handler, move]);

  return (
    <Box
      sx={{
        width: "100dvw",
        height: "100dvh",
      }}
    >
      <CesiumMapDev>
        <Slider ref={sliderRef} />
      </CesiumMapDev>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#97c4eaa3",
        }}
      >
        {timelineModeVm.items.map((item) => {
          return (
            <Button
              key={item.name}
              onClick={() => {
                console.log("item", item);
                setSelectedItem(item);
              }}
            >
              {item.name}
            </Button>
          );
        })}

        {/* {selectedItem && selectedItem.phases.length > 0 && (
          <Box>
            {selectedItem.phases.map((phase) => {
              return (
                <Button
                  key={phase.name}
                  onClick={() => {
                    setPhase(phase.phase);
                  }}
                >
                  {phase.name}
                </Button>
              );
            })}
          </Box>
        )} */}
      </Box>
    </Box>
  );
}
