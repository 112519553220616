import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import CesiumMapDev from "../../components/CesiumMap.dev";
import { masterplanVm } from "../../data/k-projects/masterplan.data";
import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../../constants";
import useViewStore from "../../stores/viewer.store";

export default function MaterplanPage() {
  const { setPhase } = useViewStore();

  useEffect(() => {
    setPhase(masterplanVm.phase);
  }, [setPhase]);

  const { setTilsets } = useViewStore();

  useEffect(() => {
    const paths = [];
    paths.push({
      path: masterplanVm.baseModelPath,
      adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
    });
    masterplanVm.items.forEach((item) => {
      paths.push({ path: item.modelPath });
    });

    setTilsets(paths);
  }, [setTilsets]);

  return (
    <Box
      sx={{
        width: "100dvw",
        height: "100dvh",
      }}
    >
      <CesiumMapDev />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#97c4eaa3",
        }}
      >
        {masterplanVm.items.map((item) => {
          return (
            <Button
              key={item.name}
              onClick={() => {
                setPhase(item.phase);
              }}
            >
              {item.name}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
}
