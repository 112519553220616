import { TPhase } from "..";
import modelings from "../modelings.data";

export type TItem = {
  name: string;
  desc: string;
  date: string;
  phase: TPhase;
  modelPath: string;
  phases: {
    name: string;
    desc: string;
    phase: TPhase;
  }[];
  data?: {
    startDt: string;
    endDt: string;
    dtType: string;
    type: string;
    place: string;
    detail: string;
    pbDate: string;
    fileType: string;
    volume: string;
    count: string;
    link: string;
    url: string;
  };
};

export type TSpaceModeVm = {
  phase: TPhase;
  items: TItem[];
};

const dataBaseUrl = process.env.REACT_APP_DATA_BASE_URL || "";

export const spaceModeVm: TSpaceModeVm = {
  phase: {
    position: [-3051457.7220468684, 4042704.247646128, 3864409.8293511905],
    orientation: {
      heading: 0.313156875998288,
      pitch: -0.27180005163537535,
      roll: 0.000030882813662813646,
    },
  },
  items: [
    {
      name: "성수클러스터_1차",
      desc: "성수클러스터",
      date: "2023.09",
      modelPath: `${dataBaseUrl}/models/3d-tiles-merged/2023/tileset.json`,
      phase: {
        position: [-3051457.7220468684, 4042704.247646128, 3864409.8293511905],
        orientation: {
          heading: 0.313156875998288,
          pitch: -0.27180005163537535,
          roll: 0.000030882813662813646,
        },
      },
      phases: modelings
        .filter((m) => m.name !== "C06")
        .map((model) => {
          return {
            name: model.name,
            desc: model.desc,
            phase: model.phase,
          };
        }),

      data: {
        startDt: "2023.09.20",
        endDt: "2023.10.07",
        dtType: "정합 및 가공 모델",
        type: "3D 메시 모델",
        place: "K 성수",
        detail: "K 성수 드론 영상 취득 데이터 정합 모델",
        pbDate: "2023.10.20",
        fileType: "tls",
        volume: "9.52",
        count: "1",
        link: "2023-10_성수아카이브 전체 지역.tls",
        url: 'https://login.microsoftonline.com/1a27bdbf-e6cc-4e33-85d2-e1c81bad930a/oauth2/authorize?client%5Fid=00000003%2D0000%2D0ff1%2Dce00%2D000000000000&response%5Fmode=form%5Fpost&response%5Ftype=code%20id%5Ftoken&resource=00000003%2D0000%2D0ff1%2Dce00%2D000000000000&scope=openid&nonce=7F880A0F7AC7D1D504035F84EC2B95925BFD0C389711849F%2DD5D63C88BB8D28F38603D92BC3F47356377EA02965CBF0089493E412AE59D936&redirect%5Furi=https%3A%2F%2Fblueholestudio%2Esharepoint%2Ecom%2F%5Fforms%2Fdefault%2Easpx&state=OD0w&claims=%7B%22id%5Ftoken%22%3A%7B%22xms%5Fcc%22%3A%7B%22values%22%3A%5B%22CP1%22%5D%7D%7D%7D&wsucxt=1&cobrandid=11bd8083%2D87e0%2D41b5%2Dbb78%2D0bc43c8a8e8a&client%2Drequest%2Did=2e2b4fa1%2D6062%2D3000%2Dbf52%2De2b2b83d6cd5',
      },
    },
    {
      name: "성수클러스터_2차",
      desc: "성수클러스터",
      date: "2024.04",
      modelPath: `${dataBaseUrl}/models/3d-tiles-merged/2024/tileset.json`,
      phase: {
        position: [-3051457.7220468684, 4042704.247646128, 3864409.8293511905],
        orientation: {
          heading: 0.313156875998288,
      pitch: -0.27180005163537535,
      roll: 0.000030882813662813646,
        },
      },
      phases: modelings.map((model) => {
        return {
          name: model.name,
          desc: model.desc,
          phase: model.phase,
        };
      }),
      data: {
        startDt: "2024.00.00",
        endDt: "2024.00.00",
        dtType: "정합 및 가공 모델",
        type: "3D 메시 모델",
        place: "K 성수",
        detail: "K 성수 드론 영상 취득 데이터 정합 모델",
        pbDate: "2024.00.00",
        fileType: "tls",
        volume: "00.00",
        count: "1",
        link: "Link",
        url: '',
      },
    },  
    {
      name: "HQ_1차",
      desc: "이마트",
      date: "2023.04",
      modelPath: `${dataBaseUrl}/models/2023/Region16_KP-E/emart_04/tileset.json`,
      phase: {
        position: [-3051073.4905346646, 4041622.284854461, 3865018.485831244],
        orientation: {
          heading: 0.978823452425793,
          pitch: -0.34326038565677863,
          roll: 0.0000044044506495311,
        },
      },
      phases: [
        {
          name: "HQ_1차",
          desc: "이마트",
          phase: {
            position: [
              -3051073.4905346646, 4041622.284854461, 3865078.485831244,
            ],
            orientation: {
              heading: 0.978823452425793,
              pitch: -0.34326038565677863,
              roll: 0.0000044044506495311,
            },
          },
        },
      ],
      data: {
        startDt: "2023.04.09",
        endDt: "2023.04.13",
        dtType: "정합 및 가공 모델",
        type: "3D 메시 모델",
        place: "HQ 부지",
        detail: "HQ 부지 드론 영상 취득 데이터 정합 모델",
        pbDate: "2023.06.15",
        fileType: "obj",
        volume: "6.58",
        count: "1",
        link: "https://drive.google.com/",
        url: 'https://drive.google.com/drive/folders/14uPOvpn49D1uc2hnQuQEqs8p_r3ycKFP',
      },
    },
    {
      name: "C03_1차",
      desc: "삼양비지네스폼",
      date: "2023.11",
      modelPath: `${dataBaseUrl}/models/2023/Region_20_Samyang/tileset.json`,
      phase: {
        position: [-3051526.9724106956, 4040922.299969804, 3865250.0339388046],
        orientation: {
          heading: 3.891116242212814,
          pitch: -0.4994544177360676,
          roll: 0.000004289985971794863,
        },
      },
      phases: [
        {
          name: "C03_1차",
          desc: "삼양비지네스폼",
          phase: {
            position: [
              -3051546.9724106956, 4040942.299969804, 3865295.0339388046,
            ],
            orientation: {
              heading: 3.891116242212814,
              pitch: -0.4994544177360676,
              roll: 0.000004289985971794863,
            },
          },
        },
      ],
      data: {
        startDt: "2023.11.15",
        endDt: "2023.11.22",
        dtType: "정합 및 가공 모델",
        type: "3D 메시 모델",
        place: "삼양비지네스폼",
        detail: "삼양비지네스폼 드론 영상 취득 데이터 정합 모델",
        pbDate: "2023.12.08",
        fileType: "tls",
        volume: "22.2",
        count: "1",
        link: "2023_삼양비즈니스폼_3D_Tiledmodel.tls",
        url: 'https://login.microsoftonline.com/1a27bdbf-e6cc-4e33-85d2-e1c81bad930a/oauth2/authorize?client%5Fid=00000003%2D0000%2D0ff1%2Dce00%2D000000000000&response%5Fmode=form%5Fpost&response%5Ftype=code%20id%5Ftoken&resource=00000003%2D0000%2D0ff1%2Dce00%2D000000000000&scope=openid&nonce=BC32935DD7A7590D7139EC9880F55E75737F4DB8915D1A3F%2D7C5EA222FF701477C79B093C4EBCD378F565BD56EF0737980D2D407304A7E2A1&redirect%5Furi=https%3A%2F%2Fblueholestudio%2Esharepoint%2Ecom%2F%5Fforms%2Fdefault%2Easpx&state=OD0w&claims=%7B%22id%5Ftoken%22%3A%7B%22xms%5Fcc%22%3A%7B%22values%22%3A%5B%22CP1%22%5D%7D%7D%7D&wsucxt=1&cobrandid=11bd8083%2D87e0%2D41b5%2Dbb78%2D0bc43c8a8e8a&client%2Drequest%2Did=4f284fa1%2Db06b%2D3000%2Dca10%2D7b305475b17e',
      },
    },
    {
      name: "X01_1차",
      desc: "한일피복",
      date: "2024.05",
      modelPath: `${dataBaseUrl}/models/2024/buildings/hanil_laserscans/tileset.json`,
      phase: {
        position: [-3050485.1133681866, 4041355.9834437915, 3865550.354248671],
        orientation: {
          heading: 5.616613392225446,
          pitch: -0.3687117562623716,
          roll: 0.0000002773357756780115,
        },
      },
      phases: [
        {
          name: "X01_1차",
          desc: "한일피복",
          phase: {
            position: [
              -3050480.1133681866, 4041368.9834437915, 3865595.354248671,
            ],
            orientation: {
              heading: 5.616613392225446,
              pitch: -0.3687117562623716,
              roll: 0.0000002773357756780115,
            },
          },
        },
      ],
      data: {
        startDt: "2024.00.00",
        endDt: "2024.00.00",
        dtType: "정합 및 가공 모델",
        type: "3D 메시 모델",
        place: "한일피복",
        detail: "한일피복 드론 영상 취득 데이터 정합 모델",
        pbDate: "2024.00.00",
        fileType: "tls",
        volume: "00.00",
        count: "1",
        link: "Link",
        url: '',
      },
    },
  ],
};
